import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

import { PATH_AUTH } from '../../routes/paths';
// import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------
const random = (length = 8) => {
  // Declare all characters
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  // Pick characers randomly
  let str = '';
  for (let i = 0; i < length; i += 1) {
    str += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return str;
};

const initialState = {
  isLoading: false,
  qr: '',
  error: null,
  fcm_token: random(10),
  device_id: random(7),
  user: {},
  token: '',
  access_token: '',
  api_Key: '1a502da1-00e4-49bb-987a-ca3ba232a846',
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TOKEN
    getTokenSuccess(state, action) {
      state.isLoading = false;
      state.token = action.payload;
    },
    getQRSuccess(state, action) {
      state.isLoading = false;
      state.qr = action.payload;
    },
    // GET ACCESS_TOKEN
    getAccessTokenSuccess(state, action) {
      state.isLoading = false;
      state.access_token = action.payload;
    },

    setUser(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },

    // reset
    reset(state) {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.fcm_token = initialState.fcm_token;
      state.device_id = initialState.device_id;
      state.user = initialState.user;
      state.qr = initialState.qr;
      state.token = initialState.token;
      state.access_token = initialState.access_token;
    },

    // LOGOUT
    logout(state) {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.fcm_token = initialState.fcm_token;
      state.device_id = initialState.device_id;
      state.user = initialState.user;
      state.qr = initialState.qr;
      state.token = initialState.token;
      state.access_token = initialState.access_token;
    },

    // // reset_Token
    // resetTokens(state, action) {
    //   state.access_token = '';
    //   state.token = '';
    // },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getTokenSuccess, hasError, reset } = slice.actions;

// ----------------------------------------------------------------------

// ---------------------------------------------------------

export function getToken(email, password) {
  return async () => {
    const data = new FormData();
    data.append('fcm_token', initialState.fcm_token);
    data.append('device_id', initialState.device_id);
    data.append('email', email);
    data.append('password', password);

    const config = {
      method: 'post',
      url: `https://api.hmomen.com/dashboard-api/user/login?api_key=${initialState.api_Key}`,
      data,
    };
    // dispatch(slice.actions.startLoading());

    try {
      const response = await axios(config);
      dispatch(slice.actions.getTokenSuccess(response.data.result.token));
      if (response.data.result.qr) {
        dispatch(slice.actions.getQRSuccess(response.data.result.qr));
      }
      //   dispatch(slice.actions.setUser([email, password]));
      // console.log(response.data.result.token);
      window.location.href = PATH_AUTH.conformlog;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// //   -------------------------------------------------------

export function getAccessToken(token, pin) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const data = new FormData();
    data.append('token', token);
    data.append('pin', pin);
    const config = {
      method: 'post',
      url: `https://api.hmomen.com/dashboard-api/user/login/2fa?api_key=${initialState.api_Key}`,
      data,
    };
    try {
      const response = await axios(config);
      dispatch(slice.actions.getAccessTokenSuccess(response.data.result.access_token));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      setTimeout(() => {
        dispatch(slice.actions.hasError(null));
      }, 5000);
    }
  };
}

export function getUser(accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'get',
        url: `https://api.hmomen.com/dashboard-api/user/me?api_key=${initialState.api_Key}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await axios(config);
      dispatch(slice.actions.setUser(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------------------------------
export function logout(accessToken, deviceId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/user/logout?device_id=${deviceId}&api_key=${initialState.api_Key}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      await axios(config);
      dispatch(slice.actions.logout());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetState() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.reset());
      // console.log(response.data.result.access_token);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// export const getAccessToken = (token, pin) => (dispatch) => {
//   const data = new FormData();
//   data.append('token', token);
//   data.append('pin', pin);

//   const config = {
//     method: 'post',
//     url: 'https://api.hmomen.com/dashboard-api/user/login/2fa?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
//     data,
//   };

//   axios(config)
//     .then((response) => {
//       console.log(response.data.result.access_token);
//       dispatch({
//         type: GET_FATOKEN,
//         payload: response.data.result.access_token,
//       });
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// };

// ---------------------------------------------
