const ar = {
  demo: {
    title: `Arabic`,
    introduction: `لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل كتاب عينة. لقد نجت ليس فقط خمسة قرون ، ولكن أيضًا القفزة في التنضيد الإلكتروني ، وظلت دون تغيير جوهري. تم نشره في الستينيات من القرن الماضي بإصدار أوراق Letraset التي تحتوي على مقاطع Lorem Ipsum ، ومؤخرًا مع برامج النشر المكتبي مثل Aldus PageMaker بما في ذلك إصدارات Lorem Ipsum.`,
  },

  // Ganeral
  ganeral: {
    haqybat_almomen: `حقيبة المؤمن`,
    Back: `رجوع`,
    Dashboard: `لوحة تحكم`,
    section: `قسم`,
    CONTENT_MANAGER: `إدارة المحتويات`,
    Content_manager: `إدارة المحتويات`,
    log_out: `تسجيل الخروج`,
    add_new_entity: `إضافة محتوى جديد`,
    entities_found: `محتوى موجود`,
    search: `بحث...`,
    search_platform: `بحث عن المنصة...`,
    go_to_home: `العودة إلى الصفحة الرئيسية`,
    no_filter: 'بدون فلتر',
  },

  // Status
  status: {
    save: `حفظ`,
    added: `تمت الإضافة`,
    updated: `تم التحديث`,
    editing: `تعديل`,
    empty_rows: `الحقول فارغة...يرجى ملئها و إعادة المحاولة.`,
    empty_file: `لم يتم إختيار ملف...يرجى إختيار ملف و إعادة المحاول`,
    file_uploaded: 'تم رفع الملف',
    file_deleted: 'تم حذف الملف',
    successfully: `بنجاح`,
    successfully_uploaded: 'تم تحميل الصوت بنجاح',
    approved: `قبول`,
    disapproved: `رفض`,
    no_data: `لا يوجد بيانات`,
    no_data_userShare: `لا يوجد بيانات للموافقة عليها`,
    rows_deleted: `تم حذف المحتوى بنجاح!`,
    true: `صح`,
    false: `خطأ`,
    userBlocked: `تم حظر المستخدم`,
    userUnblocked: `تم إلغاء حظر المستخدم`,
    add: `إضافة`,
    remove: `حذف`,
    roleRemoved: `تم حذف الدور`,
    roleAdded: `تم إضافة`,
  },

  // Errors
  Error: {
    add_schedule_error: `يرجى تحديد التاريخ والوقت ونوع الإجراء لإكمال الإضافة ...`,
    edit_schedule_error: `يرجى تحديد المحتوى المجدول والتاريخ والوقت ونوع الإجراء لإكمال التعديل ...`,
    delete_schedule_error: `الرجاء تحديد المحتوى المجدول لحذفه ...`,
    not_found_title: `عذرا، لم يتم العثور على الصفحة!`,
    not_found_msg: `معذرةً، لم نتمكن من العثور على الصفحة التي تبحث عنها. ربما أخطأت في كتابة الرابط؟ الرجاء التحقق من التهجئة.`,
    page_not_found: `خطأ 404| الصفحة غير موجودة`,
    server_error: `خطأ 500| خطأ في الخادم الداخلي`,
    server_error_msg: `كان هناك خطأ، الرجاء المحاولة مجددا لاحقا`,
    no_permission: `خطأ 403| ممنوع`,
    no_permission_title: `بلا تصريح`,
    no_permission_msg: `الصفحة التي تحاول الوصول إليها لا تملك تصريحها. الرجاء الرجوع إلى مسؤول النظام الخاص بك`,
    under_maintenance: `تحت الصيانة`,
    under_maintenance_title: `الموقع حاليا تحت الصيانة.`,
    under_maintenance_msg: `نحن نعمل جاهدين حاليا على هذه الصفحة!`,
  },
  // Login

  Login: {
    email: `البريد الألكتروني`,
    password: `كلمة المرور`,
    login: `تسجيل الدخول`,
    login_msg: `تسجيل الدخول للوحة التحكم`,
    wait_msg: `...يرجى الإنتضار`,
    details: `أدخل التفاصيل الخاصة بك أدناه.`,
    conform: `تأكيد`,
    conform_msg: `خطوة واحدة أخرى لإكمال تسجيل الدخول`,
    pin_number: `الرقم التعريفي`,
    continue_login: `إكمال تسجيل الدخول`,
    pin_required: `الرقم التعريفي مطلوب`,
    email_required: `الإيميل مطلوب`,
    password_required: `كلمة المرور مطلوبة`,
    pin_msg: `امسح رمز Qr باستخدام تطبيق Google Authentator للحصول على رقم التعريف الشخصي`,
  },

  // Information card
  information_card: {
    information: `معلومات`,
    created: ` تم إنشائها بتاريخ`,
    by: `من قبل`,
    last_updated: `تاريخ اخر تحديث`,
    schedules: `جَدولة`,
    published: `نشر`,
    unpublished: `إلغاء النشر`,
    localization: `قسم اللغات`,
    language: `اللغة`,
    no_update: `لم يتم التحديث الى الآن`,
  },

  //   Entity Dialog
  Entity_dialog: {
    time_date: `اختر تاريخ و وقت`,
    action_type: `نوع الإجراء`,
    action_date: ` تاريخ الإجراء`,
    description: `وصف`,
    pick_schedule_edit: ` أختر جَدولة للقيام بتعديلها`,
    pick_schedule_delete: `أختر جَدولة للقيام بحذفها `,
    no_scheduled_date: `لا يوجد محتوى مجَدول`,
    entity_publish: `نشر`,
    entity_unpublish: `إلغاء النشر`,
    row_delete_msg: `هل أنت متأكد أنك تريد حذف هذا المحتوى؟`,
    unblock_msg: `هل أنت متأكد أنك تريد إلغاء حظر المستخدم؟`,
    block_msg: `هل أنت متأكد أنك تريد حظر المستخدم؟`,
  },

  // Scheduling
  schedule: {
    schedule: `جَدولة`,
    publish_options: `خيارات النشر`,
    publish_now: `النشر الآن`,
    publish: `تم النشر`,
    draft: `مسودة`,
    unpublish: `إلغاء النشر`,
    unpublished: `تم إلغاء النشر`,
    schedule_publish: `جَدولة النشر`,
    update_schedule: `تحديث النشر المجدول`,
    delete_scheduled: ` حذف النشر المجدول`,
    confirmation: `تأكيد`,
    unpublish_msg: `إلغاء نشر هذا المحتوى سيؤدي الى ارجاعه الى نسخة مسودة, هل انت متأكد من إلغاء النشر؟ `,
    published_version: `نسخة منشورة`,
    draft_version: `نسخة مسودة`,
    scheduling: `جَدولة...`,
    scheduling_1: `جَدولة`,
    scheduling_msg: `للقيام بعملية النشر او إلغاء النشر لاحقا, يرجى اختيار التاريخ و الوقت الذي تريده.`,
    added_scheduling: `تم إضافة نشر مجدول`,
    scheduling_edit: `تعديل الجدولة`,
    scheduling_edit_msg: `لتعديل النشر المجدول, يرجى ملئ البيانات المطلوبة ادناه.`,
    edited_scheduling: `تم تعديل النشر المجدول`,
    scheduling_delete: `حذف الجَدولة...`,
    scheduling_delete_msg: `هل انت متأكد من حذف بيانات الجَدولة التالية ؟`,
    deleted_scheduling: `تم حذف الجَدولة`,
    unblock: `إلغاء حظر المستخدم`,
    block: `حظر المستخدم`,
  },

  // Buttons
  Buttons: {
    cancel_btn: `إلغاء`,
    edit_btn: `تعديل`,
    delete_btn: `حذف`,
    block_btn: `حظر`,
    unblock_btn: `إلغاء الحظر`,
    schedule_btn: `جَدولة`,
    yes_btn: `نعم`,
    no_btn: `كلا`,
    request_qr: `طلب رمز الـQR`,
    show_qr: `إظهار رمز الـQR`,
    role_msg: `إذا كنت ترغب في إضافة أو إزالة الدور، اختر الدور من ادناه ثم اختر الإجراء الذي تريده.`,
  },

  // Home Section
  home: {
    COLLECTION_TYPES: `أنواع المحتويات`,
    System: `النظام`,
    Content: `المحتوى`,
    Marketing: `التسويق`,
    title: `مرحبا في لوحة التحكم`,
    description: `اختر من ادناه:`,
  },

  navTitles: {
    Dashboard: `لوحة تحكم`,
    Tasbih: `تسبيح`,
    Khatma: `ختمة`,
    Wallpaper: `خلفيات`,
    Album: 'ألبوم',
    Audio: 'صوتيات',
    AudioHome: 'واجهة الصوتيات',
    Playlist: 'قائمة التشغيل',
    Reciter: 'القارئ',
    file: `ملف الخلفيات`,
    Category: `الفئة`,
    Designer: `المصمم`,
    Users: `المستخدمون`,
    Hijri: 'التاريخ الهجري',
    Roles: `الأدوار`,
    Action: `الإجراء`,
    Localization: `اللغات`,
    Banner: `بنر`,
    Tag: `إشارة`,
    Popup: `الإعلانات المبوبة`,
    Occasion: `حدث`,
    UserShare: `مشاركات المستخدم`,
  },
  // Home Sections
  content: {
    Tasbih: `تسبيح`,
    Khatma: `ختمة`,
    Wallpaper: `خلفيات`,
    file: `ملف الخلفيات`,
    Category: `الفئة`,
    Designer: `المصمم`,
    Album: 'ألبوم',
    Audio: 'صوتيات',
    AudioHome: 'واجهة الصوتيات',
    Playlist: 'قائمة التشغيل',
    Reciter: 'القارئ',
    DashboardGreetings: `مرحبا بكم في لوحة التحكم الخاصة بقسم المحتوى`,
  },

  system: {
    Users: `المستخدمون`,
    Roles: `الأدوار`,
    Action: `الإجراء`,
    Hijri: 'التاريخ الهجري',
    Localization: `اللغات`,
    DashboardGreetings: `مرحبا بكم في لوحة التحكم الخاصة بقسم النظام`,
  },

  marketing: {
    Banner: `بنر`,
    Tag: `إشارة`,
    Popup: `الإعلانات المبوبة`,
    Occasion: `حدث`,
    UserShare: `مشاركات المستخدم`,
    DashboardGreetings: `مرحبا بكم في لوحة التحكم الخاصة بقسم التسويق`,
  },

  TableHeaders: {
    id: `المعرف`,
    name: `الأسم`,
    title: `العنوان`,
    start_date: `تاريخ البدأ`,
    end_date: `تاريخ النهاية`,
    type: `النوع`,
    force: 'تعديل التاريخ',
    natural: 'تاريخ العادي',
    forcing_valid_until: 'تحديد التاريخ الهجري الى تاريخ',
    forcing_date: 'التاريخ الهجري',
    amount: 'القيمة',
    description: `الوصف`,
    creation_date: `تاريخ الإنشاء`,
    status: `الحالة`,
    slide_caption: `العنوان الفرعي للشرائح`,
    image_description: `وصف الصورة`,
    slide_sort: `تسلسل الشريحة`,
    platform: `منصة`,
    key: `مفتاح`,
    android: `اندرويد`,
    ios: ` آي أو إس`,
    all: `الكل`,
    caption: `عنوان فرعي`,
    action_type: 'نوع الإجراء',
    link: 'رابط',
    add_link: 'أصف رابط',
    add_to_playlist: 'إضافة الى قائمة التشغيل',
    action: 'قائمة الإجراءات',
  },

  // Content Section
  Tasbih: {
    duration: `المدة`,
    goal: `الهدف`,
    tasbih_image: `صورة التسبيح`,
  },

  Album: {
    image: 'صورة الألبوم',
  },
  Audio: {
    audio: 'صوتيات',
    audio_add: 'أختر ملف صوتي',
    file_name: 'أسم الملف',
    image: 'صورة الصوتيات',
    upload: 'تحميل ملف الصوت',
    delete: 'حذف الملف',
    select: 'اختر قائمة تشغيل',
    file_exist: 'يوجد ملف صوتي مرفوع',
  },
  Reciter: {
    image: 'صورة القارئ',
  },

  Playlist: {
    add: ' تمت الإضافة الى قائمة تشغيل',
    remove: ' تم الحذف من قائمة تشغيل',
    image: `صورة لقائمة التشغيل`,
  },
  Khatma: {
    select_type: `اختر نوع من ادناه`,
    quran: `قرآن`,
    hizib: `حزب`,
    sura: `سورة`,
    row_duplicated: `تم نسخ المحتوى بنجاح!`,
  },

  Designer: {},

  Category: {
    image: `صورة للفئة`,
  },

  Wallpaper: {
    wallpaper: `الخلفيات`,
    action_key: `مفتاح الإجراء`,
    featured: `متميز`,
    key: `مفتاح`,
    wallpaper_image: `صورة`,
    wallpaper_published: `تم نشر الخلفية!`,
    wallpaper_unpublished: `تم إلغاء نشر الخلفية!`,
  },

  // Marketing Section

  Banner: {
    banner_image: `صور البنر`,
  },

  Popup: {
    popup_image: `صورة الإعلانات المبوبة`,
    pick_a_key: `اختر مفتاح`,
    pick_action: `اختر إجراء`,
    in_app: `IN_APP`,
    link: `LINK`,
  },
  UserShare: {
    show: `إظهار:`,
    all: `الكل`,
    approved: `موافق علية`,
    disapproved: `مرفوض`,
    awaiting_approval: `في إنتظار الموافقة`,
  },

  // System section
  Roles: {
    Admin: `أدمن`,
    User: `مستخدم`,
    priority: `الأولوية`,
    can_access_dashboard: `إمكانية الوصول للوحة التحكم`,
    can_access_banner: `إمكانية الوصول للبنر  `,
    adding_banner: `إضافة بنر`,
    banner_acceptability: ` قبول البنر`,
    delete_banner: `حذف البنر`,
    can_access_wall: `إمكانية الوصول الى الخلفية`,
    adding_wall: `إضافة خلفية`,
    wall_acceptability: `قبول الخلفية`,
    delete_wall: `حذف الخلفية`,
    can_access_popups: `إمكانية الوصول الى الإعلانات المبوبة `,
    adding_popups: `سماح إضافة اعلانات مبوبة`,
    popups_acceptability: `قبول الإعلانات المبوبة`,
    delete_popups: `حذف الإعلانات المبوبة`,
    can_access_occasion: `إمكانية الوصول الى الحدث`,
    adding_occasion: `إضافة حدث`,
    occasion_acceptability: `قبول الحدث`,
    delete_occasion: `حذف الحدث`,
    can_access_share: `الوصول الى المشاركة`,
    share_acceptability: `قبول المشاركة`,
    manage_role: `إدارة الأدوار`,
    manage_youtube: `إدارة اليوتيوب`,
    manage_tasbih: `إدارة تسبيح`,
    manage_arbaein: `إدارة الاربعين `,
    manage_khatma: `إدارة الختمة`,
    manage_configuration: `إدارة الترتيب`,
    manage_language: ` إدارة اللغة`,
    manage_audio: `إدارة الصوت`,
    can_access_user: `الوصول للمستخدم`,
    block_user: `منع المستخدم`,
    add_user: `إضافة مستخدم`,
    update_user: `تحديث المستخدم`,
    delete_user: `حذف المستخدم`,
    manage_tag: `إدارة الإشارة`,
    Content: `قسم المحتوى`,
    System: `قسم النظام`,
    Marketing: `قسم التسويق`,
  },

  Users: {
    full_name: `الأسم الكامل`,
    email: `الإيميل`,
  },

  Localization: {
    select_language_code: `اختر رمز اللغة`,
    select_country_code: `اختر رمز الدولة`,
  },

  HijriDate: {
    hijri_forcing_date: 'التاريخ الهجري',
  },

  Action: {
    code: `رمز`,
    uniqe_code: `ادخل رمز فريد`,
  },
};

export default ar;
