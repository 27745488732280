// routes
import { PATH_CONTENT } from '../../../../routes/paths';

import SvgIconStyle from '../../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  booking: getIcon('ic_booking'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Content Manager',

    items: [
      { title: 'Dashboard', path: PATH_CONTENT.Dashboard, icon: ICONS.dashboard },

      { title: 'Tasbih', path: PATH_CONTENT.contentManager.tasbih, icon: ICONS.menuItem },

      { title: 'Khatma', path: PATH_CONTENT.contentManager.khatma, icon: ICONS.menuItem },

      {
        title: 'Wallpaper',
        path: PATH_CONTENT.contentManager.wallpaper,
        icon: ICONS.menuItem,

        children: [
          { title: 'file', path: PATH_CONTENT.contentManager.wallpaper },
          { title: 'Designer', path: PATH_CONTENT.contentManager.designer },
          { title: 'Category', path: PATH_CONTENT.contentManager.category },
        ],
      },

      {
        title: 'Audio',
        path: PATH_CONTENT.contentManager.audio,
        icon: ICONS.menuItem,

        children: [
          { title: 'AudioHome', path: PATH_CONTENT.contentManager.audioHome },
          { title: 'Audio', path: PATH_CONTENT.contentManager.audio },
          { title: 'Reciter', path: PATH_CONTENT.contentManager.reciter },
          { title: 'Album', path: PATH_CONTENT.contentManager.album },
          { title: 'Playlist', path: PATH_CONTENT.contentManager.playlist },
        ],
      },
    ],
  },
];

export default navConfig;
