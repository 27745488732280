// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_CONTENT = '/content';
const ROOTS_SYSTEM = '/system';
const ROOTS_MARKETING = '/marketing';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  conformlog: path(ROOTS_AUTH, '/conformLog'),
};
export const PATH_INTRO = {
  intro: '/Home',
};

// Content Paths

export const PATH_CONTENT = {
  root: ROOTS_CONTENT,
  Dashboard: path(ROOTS_CONTENT, '/dashboard'),
  contentManager: {
    root: path(ROOTS_CONTENT, '/contentManager'),
    khatma: path(ROOTS_CONTENT, '/contentManager/khatma'),
    tasbih: path(ROOTS_CONTENT, '/contentManager/tasbih'),
    wallpaper: path(ROOTS_CONTENT, '/contentManager/file'),
    wallpaper_add: path(ROOTS_CONTENT, '/contentManager/wallpaperAdd'),
    khatma_add: path(ROOTS_CONTENT, '/contentManager/khatmaAdd'),
    tasbih_add: path(ROOTS_CONTENT, '/contentManager/tasbihAdd'),
    designer: path(ROOTS_CONTENT, '/contentManager/designer'),
    designer_add: path(ROOTS_CONTENT, '/contentManager/designerAdd'),
    category: path(ROOTS_CONTENT, '/contentManager/category'),
    category_add: path(ROOTS_CONTENT, '/contentManager/categoryAdd'),
    audio: path(ROOTS_CONTENT, '/contentManager/audio'),
    audio_add: path(ROOTS_CONTENT, '/contentManager/audioAdd'),
    audioHome: path(ROOTS_CONTENT, '/contentManager/audioHome'),
    audioHome_add: path(ROOTS_CONTENT, '/contentManager/audioHomeAdd'),
    playlist: path(ROOTS_CONTENT, '/contentManager/playlist'),
    playlist_add: path(ROOTS_CONTENT, '/contentManager/playlistAdd'),
    album: path(ROOTS_CONTENT, '/contentManager/album'),
    album_add: path(ROOTS_CONTENT, '/contentManager/albumAdd'),
    reciter: path(ROOTS_CONTENT, '/contentManager/reciter'),
    reciter_add: path(ROOTS_CONTENT, '/contentManager/reciterAdd'),
  },
};

// System paths
export const PATH_SYSTEM = {
  root: ROOTS_SYSTEM,
  Dashboard: path(ROOTS_SYSTEM, '/dashboard'),
  contentManager: {
    root: path(ROOTS_SYSTEM, '/contentManager'),
    roles: path(ROOTS_SYSTEM, '/contentManager/roles'),
    roles_add: path(ROOTS_SYSTEM, '/contentManager/rolesAdd'),
    users: path(ROOTS_SYSTEM, '/contentManager/users'),
    users_add: path(ROOTS_SYSTEM, '/contentManager/usersAdd'),
    action: path(ROOTS_SYSTEM, '/contentManager/action'),
    action_add: path(ROOTS_SYSTEM, '/contentManager/actionAdd'),
    localization: path(ROOTS_SYSTEM, '/contentManager/localization'),
    localization_add: path(ROOTS_SYSTEM, '/contentManager/localizationAdd'),
    hijriDate: path(ROOTS_SYSTEM, '/contentManager/hijriDate'),
    hijriDate_add: path(ROOTS_SYSTEM, '/contentManager/hijriDateAdd'),
  },
};

// Marketing paths

export const PATH_MARKETING = {
  root: ROOTS_MARKETING,
  Dashboard: path(ROOTS_MARKETING, '/dashboard'),
  contentManager: {
    root: path(ROOTS_MARKETING, '/contentManager'),
    banner: path(ROOTS_MARKETING, '/contentManager/banner'),
    popup: path(ROOTS_MARKETING, '/contentManager/popup'),
    tag: path(ROOTS_MARKETING, '/contentManager/tag'),
    userShare: path(ROOTS_MARKETING, '/contentManager/userShare'),
    occasion: path(ROOTS_MARKETING, '/contentManager/occasion'),
    banner_add: path(ROOTS_MARKETING, '/contentManager/bannerAdd'),
    popup_add: path(ROOTS_MARKETING, '/contentManager/popupAdd'),
    occasion_add: path(ROOTS_MARKETING, '/contentManager/occasionAdd'),
    tag_add: path(ROOTS_MARKETING, '/contentManager/tagAdd'),
    userShare_add: path(ROOTS_MARKETING, '/contentManager/userShareAdd'),
  },
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
};
