import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
//
import { dispatch } from '../../store';
// ----------------------------------------------------------------------

const initialState = {
  users: [],
  user: {},
  error: '',
  success: false,
  user_qr: '',
  user_qr_token: '',
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setSuccess(state) {
      state.success = false;
    },
    setTrueSuccess(state) {
      state.success = true;
    },
    // GET users
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },
    getUserQrSuccess(state, action) {
      state.isLoading = false;
      state.user_qr = action.payload;
    },
    getUserTokenSuccess(state, action) {
      state.isLoading = false;
      state.user_qr_token = action.payload;
    },

    // CREATE user
    createUserSuccess(state, action) {
      const newuser = action.payload;
      state.isLoading = false;
      state.users = [...state.users, newuser];
      state.success = true;
    },

    // UPDATE user
    updateUserSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.users.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.users = updateEvent;
      state.success = true;
    },

    // DELETE user
    deleteUserSuccess(state, action) {
      const eventId = action.payload;
      const deleteEvent = state.users.filter((event) => event.id !== eventId);
      state.users = deleteEvent;
      state.success = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteConform, hasError, setSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getUsers(accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'get',
        url: 'https://api.hmomen.com/dashboard-api/user?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846&only_staff=true',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(config);
      dispatch(slice.actions.getUsersSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUser(userId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'get',
        url: `https://api.hmomen.com/dashboard-api/user/${userId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(config);
      dispatch(slice.actions.getUserSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resetUser() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getUserSuccess({}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createUser(newuser, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(newuser);

      const config = {
        method: 'post',
        url: 'https://api.hmomen.com/dashboard-api/user?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };

      const response = await axios(config);
      dispatch(slice.actions.createUserSuccess(response.data.result));
      dispatch(getUsers(accessToken));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateUser(userId, updateuser, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(updateuser);

      const config = {
        method: 'patch',
        url: `https://api.hmomen.com/dashboard-api/user/${userId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };
      const response = await axios(config);
      dispatch(slice.actions.updateUserSuccess(response.data.result));
      dispatch(getUser(userId, accessToken));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteUser(userId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/user/${userId}/delete?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      dispatch(slice.actions.deleteUserSuccess(userId));
      dispatch(getUsers(accessToken));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getQr(token, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'get',
        url: `https://api.hmomen.com/dashboard-api/user/qr?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846&token=${token}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(config);
      dispatch(slice.actions.getUserQrSuccess(response.data.result.qr));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function requestQr(userId, accessToken, duration) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(duration);

      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/user/request-qr/${userId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };

      const response = await axios(config);
      dispatch(slice.actions.getUserTokenSuccess(response.data.result.token));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function BlockUser(userId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/user/${userId}/block?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      dispatch(getUsers(accessToken));
      // dispatch(slice.actions.publishBannerSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function unBlockUser(userId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/user/${userId}/unblock?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      dispatch(getUsers(accessToken));
      // dispatch(slice.actions.publishBannerSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    getUsers(accessToken);
  };
}

export function AddRole(userId, accessToken, roles) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(roles);

      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/user/${userId}/add-roles?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };
      await axios(config);
      dispatch(slice.actions.setTrueSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function RemoveRole(userId, accessToken, roles) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(roles);

      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/user/${userId}/remove-roles?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };
      await axios(config);
      dispatch(slice.actions.setTrueSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
