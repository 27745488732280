import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
//
import { dispatch } from '../../store';
// ----------------------------------------------------------------------

const initialState = {
  roles: [],
  role: {},
  error: '',
  success: false,
};

const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setSuccess(state) {
      state.success = false;
    },
    // GET roles
    getRolesSuccess(state, action) {
      state.isLoading = false;
      state.roles = action.payload;
    },
    getRoleSuccess(state, action) {
      state.isLoading = false;
      state.role = action.payload;
    },

    // CREATE role
    createRoleSuccess(state, action) {
      const newrole = action.payload;
      state.isLoading = false;
      state.roles = [...state.roles, newrole];
      state.success = true;
    },

    // UPDATE role
    updateRoleSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.roles.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.roles = updateEvent;
      state.success = true;
    },

    // DELETE role
    deleteRoleSuccess(state, action) {
      const eventId = action.payload;
      const deleteEvent = state.roles.filter((event) => event.id !== eventId);
      state.roles = deleteEvent;
      state.success = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteConform, hasError, setSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getRoles(accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'get',
        url: 'https://api.hmomen.com/dashboard-api/role/?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(config);
      dispatch(slice.actions.getRolesSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRole(roleId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'get',
        url: `https://api.hmomen.com/dashboard-api/role/${roleId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(config);
      dispatch(slice.actions.getRoleSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function resetRole() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getRoleSuccess({}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createRole(newrole, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(newrole);

      const config = {
        method: 'post',
        url: 'https://api.hmomen.com/dashboard-api/role/?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };

      const response = await axios(config);
      dispatch(slice.actions.createRoleSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateRole(roleId, updaterole, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(updaterole);

      const config = {
        method: 'patch',
        url: `https://api.hmomen.com/dashboard-api/role/${roleId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };
      const response = await axios(config);
      dispatch(slice.actions.updateRoleSuccess(response.data.result));
      dispatch(getRole(roleId, accessToken));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteRole(roleId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'delete',
        url: `https://api.hmomen.com/dashboard-api/role/${roleId}/?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      dispatch(slice.actions.deleteRoleSuccess(roleId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
