import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
//
import { dispatch } from '../../store';
// ----------------------------------------------------------------------

const initialState = {
  tasbihs: [],
  schedules: [],
  tasbih: {},
  error: '',
  success: false,
};

const slice = createSlice({
  name: 'tasbih',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setSuccess(state) {
      state.success = false;
    },
    // GET tasbihs
    getTasbihsSuccess(state, action) {
      state.isLoading = false;
      state.tasbihs = action.payload;
    },
    getSchedulesSuccess(state, action) {
      state.isLoading = false;
      state.schedules = action.payload;
    },
    getTasbihSuccess(state, action) {
      state.isLoading = false;
      state.tasbih = action.payload;
    },

    // CREATE tasbih
    createTasbihSuccess(state, action) {
      const newTasbih = action.payload;
      state.isLoading = false;
      state.tasbihs = [...state.tasbihs, newTasbih];
      state.success = true;
    },

    createScheduleSuccess(state, action) {
      const newSchedule = action.payload;
      state.isLoading = false;
      state.schedules = [...state.schedules, newSchedule];
    },

    // UPDATE tasbih
    updateTasbihSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.tasbihs.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.tasbihs = updateEvent;
      state.success = true;
    },

    updateScheduleSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.schedules.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.schedules = updateEvent;
    },

    // DELETE tasbih
    deleteTasbihSuccess(state, action) {
      const eventId = action.payload;
      const deleteEvent = state.tasbihs.filter((event) => event.id !== eventId);
      state.tasbihs = deleteEvent;
      state.success = true;
    },

    deleteScheduleSuccess(state, action) {
      const eventId = action.payload;
      const deleteEvent = state.schedules.filter((event) => event.id !== eventId);
      state.schedules = deleteEvent;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteConform, hasError, setSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getTasbihs(accessToken, lang) {
  return async () => {
    if (lang) {
      dispatch(slice.actions.startLoading());
      try {
        const config = {
          method: 'get',
          url: `https://api.hmomen.com/dashboard-api/tasbih/?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846&lang=${lang}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          },
        };

        const response = await axios(config);
        dispatch(slice.actions.getTasbihsSuccess(response.data.result));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    } else {
      dispatch(slice.actions.startLoading());
      try {
        const config = {
          method: 'get',
          url: 'https://api.hmomen.com/dashboard-api/tasbih/?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          },
        };

        const response = await axios(config);
        dispatch(slice.actions.getTasbihsSuccess(response.data.result));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getTasbih(tasbihId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'get',
        url: `https://api.hmomen.com/dashboard-api/tasbih/${tasbihId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(config);
      dispatch(slice.actions.getTasbihSuccess(response.data.result.tasbih));
      dispatch(slice.actions.getSchedulesSuccess(response.data.result.schedules));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function resetTasbih() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getTasbihSuccess({}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createTasbih(newTasbih, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(newTasbih);

      const config = {
        method: 'post',
        url: 'https://api.hmomen.com/dashboard-api/tasbih?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };

      const response = await axios(config);
      dispatch(slice.actions.createTasbihSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateTasbih(tasbihId, updatedTasbih, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(updatedTasbih);

      const config = {
        method: 'patch',
        url: `https://api.hmomen.com/dashboard-api/tasbih/${tasbihId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };
      const response = await axios(config);
      dispatch(slice.actions.updateTasbihSuccess(response.data.result));
      dispatch(getTasbih(tasbihId, accessToken));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function publishTasbih(tasbihId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/tasbih/${tasbihId}/publish?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function hideTasbih(tasbihId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/tasbih/${tasbihId}/hide?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// -----------------------------------------

export function scheduledTasbih(tasbihId, schedulData, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(schedulData);

      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/tasbih/${tasbihId}/scheduling?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };

      const response = await axios(config);
      dispatch(slice.actions.createScheduleSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -------------------------------------------------------------------------
export function EditScheduledTasbih(tasbihId, id, schedulData, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(schedulData);

      const config = {
        method: 'patch',
        url: `https://api.hmomen.com/dashboard-api/tasbih/${tasbihId}/scheduling/${id}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };
      const response = await axios(config);
      dispatch(slice.actions.updateScheduleSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteScheduledTasbih(tasbihId, id, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'delete',
        url: `https://api.hmomen.com/dashboard-api/tasbih/${tasbihId}/scheduling/${id}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      dispatch(slice.actions.deleteScheduleSuccess(id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
export function deleteTasbih(tasbihId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'delete',
        url: `https://api.hmomen.com/dashboard-api/tasbih/${tasbihId}/?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      dispatch(slice.actions.deleteTasbihSuccess(tasbihId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
