const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },

  // Ganeral
  ganeral: {
    haqybat_almomen: `Haqybat Almomen`,
    Back: `Back`,
    Dashboard: `Dashboard`,
    CONTENT_MANAGER: `CONTENT MANAGER`,
    Content_manager: `Content Manager`,
    section: `Section`,
    log_out: `Logout`,
    add_new_entity: `Add New Entity`,
    entities_found: `entities found`,
    search: `Search...`,
    search_platform: `Search Platform...`,
    go_to_home: `Go to Home`,
    no_filter: 'No Filter',
  },

  // Status
  status: {
    save: `Save`,
    added: `Added`,
    updated: `Updated`,
    editing: `Editing`,
    empty_rows: `Rows are empty... Please fill them and try again.`,
    empty_file: `No file is selected... Please select file and try again.`,
    file_uploaded: 'File uploaded ',
    file_deleted: 'File deleted',
    successfully: `Successfully`,
    successfully_uploaded: 'Audio is Successfully Uploaded',
    approved: `Approval!`,
    disapproved: `Disapproval!`,
    no_data: `No Data Found`,
    no_data_userShare: `No Data Found For Approval`,
    rows_deleted: `Row deleted Successfully!`,
    true: `True`,
    false: `False`,
    userBlocked: `User Blocked`,
    userUnblocked: `User Unblocked`,
    add: `Add`,
    remove: `Remove`,
    roleRemoved: `Role Removed`,
    roleAdded: `Role Added`,
  },

  // Errors
  Error: {
    add_schedule_error: `Please select Date&Time and an Action type to complete the addition...`,
    edit_schedule_error: `Please select a Scheduled Data, Date&Time and an Action type to complete the edit...`,
    delete_schedule_error: `Please select a Scheduled Data to delete...`,
    not_found_title: `Sorry, page not found!`,
    not_found_msg: `Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check
    your spelling.`,
    page_not_found: `404 Page Not Found`,
    server_error: `500 Internal Server Error`,
    server_error_msg: `There was an error, please try again later`,
    no_permission: `403 Forbidden`,
    no_permission_title: `No permission`,
    no_permission_msg: `The page you're trying access has restricted access Please refer to your system administrator`,
    under_maintenance: `Maintenance`,
    under_maintenance_title: `Website currently under maintenance`,
    under_maintenance_msg: `We are currently working hard on this page!`,
  },

  // Login

  Login: {
    email: `Email Address`,
    password: `Password`,
    login: `Login`,
    login_msg: `Sign in to Dashboard`,
    wait_msg: `Please Wait...`,
    details: `Enter your details below.`,
    conform: `Conform`,
    conform_msg: `One more step to login`,
    pin_number: `Pin Number`,
    continue_login: `Continue Login`,
    pin_required: `Pin is required`,
    email_required: `Email is required`,
    password_required: `Password is required`,
    pin_msg: `Scan Qr code with google authenticator app to get pin number`,
  },
  // Information card
  information_card: {
    information: `Information`,
    created: `Created`,
    by: `By`,
    last_updated: `Last Updated`,
    schedules: `Schedules`,
    published: `PUBLISHED`,
    unpublished: `UNPUBLISHED`,
    localization: `Localization`,
    language: `Language`,
    no_update: `No update yet`,
  },

  //   Entity Dialog
  Entity_dialog: {
    time_date: `Pick Date & Time`,
    action_type: `Action Type`,
    action_date: `Action Date`,
    description: `Description`,
    pick_schedule_edit: `Pick a Schedule to Edit`,
    pick_schedule_delete: `Pick a Schedule to Delete`,
    no_scheduled_date: `No Scheduled Data`,
    entity_publish: `Publish`,
    entity_unpublish: `Unpublish`,
    row_delete_msg: `Are You sure you want to delete this row?`,
    unblock_msg: `Are you sure you want to unblock user?`,
    block_msg: `Are you sure you want to block user?`,
  },

  // Scheduling
  schedule: {
    schedule: `Schedule`,
    publish_options: `Publish options`,
    publish_now: `Publish Now`,
    publish: `Published`,
    unpublish: `Unpublish`,
    unpublished: `Unpublished`,
    schedule_publish: `Schedule publish`,
    update_schedule: `Update Scheduled Publish`,
    delete_scheduled: `Delete Scheduled Publish`,
    confirmation: `Confirmation`,
    unpublish_msg: `Unpublish this content will automatically change it to a draft. Are you sure you want to unpublish it?`,
    published_version: `Published Version`,
    draft_version: `Draft Version`,
    scheduling: `Scheduling...`,
    draft: `Draft`,
    scheduling_1: `Scheduling`,
    scheduling_msg: `To Publish or Unpublish later with schedule, please pick a date and time that you want.`,
    added_scheduling: `Added Scheduled Publishing!`,
    scheduling_edit: `Edit Scheduling...`,
    scheduling_edit_msg: `To edit schedule publish, please pick the entity you want to edit and the required data below.`,
    edited_scheduling: `Scheduled Publishing Edited`,
    scheduling_delete: `Delete Scheduling...`,
    scheduling_delete_msg: `Are you sure You want to delete the following Scheduled Publishing data?`,
    deleted_scheduling: `Scheduled Publishing Deleted'`,
    unblock: `Unblock User`,
    block: `Block User`,
  },

  // Buttons
  Buttons: {
    cancel_btn: `Cancel`,
    edit_btn: `Edit`,
    delete_btn: `Delete`,
    block_btn: `Block`,
    unblock_btn: `Unblock`,
    schedule_btn: `Schedule`,
    yes_btn: `Yes`,
    no_btn: `No`,
    request_qr: `Request QR`,
    show_qr: `Show QR`,
    role_msg: `If you want to add or remove role choose role from below and choose the action you want.`,
  },

  // Home
  home: {
    COLLECTION_TYPES: `COLLECTION TYPES`,
    System: `System`,
    Content: `Content`,
    Marketing: `Marketing`,
    title: `Welcome to Dashboard`,
    description: `Choose from below:`,
  },

  navTitles: {
    Dashboard: `Dashboard`,
    Tasbih: `Tasbih`,
    Khatma: `Khatma`,
    Wallpaper: `Wallpaper`,
    Album: 'Album',
    Audio: 'Audio',
    AudioHome: 'Audio Home',
    Playlist: 'Playlist',
    Reciter: 'Reciter',
    file: `File`,
    Category: `Category`,
    Designer: `Designer`,
    Users: `Users`,
    Roles: `Roles`,
    Action: `Action`,
    Localization: `Localization`,
    Banner: `Banner`,
    Hijri: 'Hijri Date',
    Tag: `Tag`,
    Popup: `Popup`,
    Occasion: `Occasion`,
    UserShare: `UserShare`,
  },

  // Home Sections
  content: {
    Tasbih: `Tasbih`,
    Khatma: `Khatma`,
    Wallpaper: `WALLPAPER`,
    file: `File`,
    Category: `Category`,
    Designer: `Designer`,
    Album: 'Album',
    Audio: 'Audio',
    AudioHome: 'Audio Home',
    Playlist: 'Playlist',
    Reciter: 'Reciter',
    DashboardGreetings: `Hi, Welcome back to Content Dashboard`,
  },
  system: {
    Users: `Users`,
    Roles: `Roles`,
    Hijri: 'Hijri Date',
    Action: `Action`,
    Localization: `Localization`,
    DashboardGreetings: `Hi, Welcome back to System Dashboard`,
  },
  marketing: {
    Banner: `Banner`,
    Tag: `Tag`,
    Popup: `Popup`,
    Occasion: `Occasion`,
    UserShare: `UserShare`,
    DashboardGreetings: `Hi, Welcome back to Marketing Dashboard`,
  },

  TableHeaders: {
    id: `Id`,
    name: `Name`,
    title: `Title`,
    start_date: `Start Date`,
    end_date: `End Date`,
    type: `Type`,
    key: `Key`,
    force: 'Force',
    natural: 'Normal',
    forcing_valid_until: 'Forcing Valid Until',
    forcing_date: 'Forcing Date',
    amount: 'Amount',
    description: `Description`,
    creation_date: `Creation Date`,
    status: `Status`,
    slide_caption: `Slide Caption`,
    slide_sort: `Slide Sort`,
    image_description: `Image Description`,
    platform: `Platform`,
    android: `Android`,
    ios: `IOS`,
    all: `All`,
    caption: `Caption`,
    action_type: 'Action Type',
    link: 'Link',
    add_link: 'Add Link',
    add_to_playlist: 'Add to Playlist',
    action: 'Action List',
  },
  // Content Section
  Tasbih: {
    duration: `Duration`,
    goal: `Goal`,
    tasbih_image: `Tasbih Image`,
  },

  Album: {
    image: 'Album Image',
  },
  Audio: {
    audio: 'Audio',
    audio_add: 'Pick an audio file',
    file_name: 'File Name',
    image: 'Audio Image',
    upload: 'Upload Audio',
    delete: 'Delete Audio',
    select: 'Select a playlist',

    file_exist: 'There is an audio file uploaded',
  },
  Reciter: {
    image: 'Reciter image',
  },
  Playlist: {
    add: 'Added to playlist ',
    remove: 'Removed from Playlist ',
    image: `Playlist Image`,
  },
  Khatma: {
    select_type: `Select a Type`,
    quran: `Quran`,
    hizib: `Hizib`,
    sura: `Sura`,
    row_duplicated: `Row Duplicated Successfully!`,
  },

  Designer: {},

  Category: { image: `Category Image` },

  Wallpaper: {
    wallpaper: `Wallpaper`,
    action_key: `Action Key`,
    featured: `Featured`,
    wallpaper_image: `Wallpaper Image`,
    key: `Key`,
    wallpaper_published: `Wallpaper published!`,
    wallpaper_unpublished: `Wallpaper unpublished!`,
  },

  // Marketing Section
  Banner: {
    banner_image: `Banner Image`,
  },

  Popup: {
    popup_image: `Popup Image`,
    pick_a_key: `Pick a key`,
    pick_action: `Pick Action`,
    in_app: `IN_APP`,
    link: `LINK`,
  },
  UserShare: {
    show: `Show:`,
    all: `All`,
    approved: `Approved`,
    disapproved: `Disapproved`,
    awaiting_approval: ` Awaiting approval`,
  },

  //  System Section
  Roles: {
    Admin: `Admin`,
    User: `User`,
    priority: `Priority`,
    can_access_dashboard: `Access Dashboard`,
    can_access_banner: `Access Banner`,
    adding_banner: `Adding Banner`,
    banner_acceptability: `Banner Acceptability`,
    delete_banner: `Delete Banner`,
    can_access_wall: `Access Wallpaper`,
    adding_wall: `Adding Wallpaper`,
    wall_acceptability: `Wallpaper Acceptability`,
    delete_wall: `Delete Wallpaper`,
    can_access_popups: `Access Popup`,
    adding_popups: `Adding Popup`,
    popups_acceptability: `Popup Acceptability`,
    delete_popups: `Delete Popup`,
    can_access_occasion: `Access Occasion`,
    adding_occasion: `Adding Occasion`,
    occasion_acceptability: `Occasion Acceptability`,
    delete_occasion: `Delete Occasion`,
    can_access_share: `Access Share`,
    share_acceptability: `Share Acceptability`,
    manage_role: `Manage Roles`,
    manage_youtube: `Manage Youtube`,
    manage_tasbih: `Manage Tasbih`,
    manage_arbaein: `Manage Arbaein`,
    manage_khatma: `Manage Khatma`,
    manage_configuration: `Manage Configuration`,
    manage_language: ` Manage Language`,
    manage_audio: `Manage Audio`,
    can_access_user: `Access User`,
    block_user: `Block User`,
    add_user: `Add User`,
    update_user: `Update User`,
    delete_user: `Delete user`,
    manage_tag: `Manage Tag`,
    Content: `Content Section`,
    System: `System Section`,
    Marketing: `Marketing Section`,
  },

  Users: {
    full_name: `Full Name`,
    email: `Email`,
  },

  Localization: {
    select_language_code: `Select language code`,
    select_country_code: `Select country code`,
  },

  HijriDate: {
    hijri_forcing_date: 'Forcing Date in Hijri',
  },

  Action: {
    code: `Code`,
    uniqe_code: `Uniqe Code`,
  },
};

export default en;
