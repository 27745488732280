import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
//
import { dispatch } from '../../store';
// ----------------------------------------------------------------------

const initialState = {
  popups: [],
  schedules: [],
  popup: {},
  error: '',
  success: false,
};

const slice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setSuccess(state) {
      state.success = false;
    },
    // GET popups
    getPopupsSuccess(state, action) {
      state.isLoading = false;
      state.popups = action.payload;
    },
    getPopupSuccess(state, action) {
      state.isLoading = false;
      state.popup = action.payload;
    },

    // CREATE popup
    createPopupSuccess(state, action) {
      const newPopup = action.payload;
      state.isLoading = false;
      state.popups = [...state.popups, newPopup];
      state.success = true;
    },

    // UPDATE popup
    updatePopupSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.popups.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.popups = updateEvent;
      state.success = true;
    },

    updateScheduleSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.schedules.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.schedules = updateEvent;
    },
    createScheduleSuccess(state, action) {
      const newSchedule = action.payload;
      state.isLoading = false;
      state.schedules = [...state.schedules, newSchedule];
    },
    getSchedulesSuccess(state, action) {
      state.isLoading = false;
      state.schedules = action.payload;
    },
    // DELETE popup
    deletePopupSuccess(state, action) {
      const eventId = action.payload;
      const deleteEvent = state.popups.filter((event) => event.id !== eventId);
      state.popups = deleteEvent;
      state.success = true;
    },
    deleteScheduleSuccess(state, action) {
      const eventId = action.payload;
      const deleteEvent = state.schedules.filter((event) => event.id !== eventId);
      state.schedules = deleteEvent;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteConform, hasError, setSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getPopups(accessToken, lang) {
  return async () => {
    if (lang) {
      dispatch(slice.actions.startLoading());
      try {
        const config = {
          method: 'get',
          url: `https://api.hmomen.com/dashboard-api/popup/?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846&lang=${lang}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          },
        };

        const response = await axios(config);
        dispatch(slice.actions.getPopupsSuccess(response.data.result));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    } else {
      dispatch(slice.actions.startLoading());
      try {
        const config = {
          method: 'get',
          url: 'https://api.hmomen.com/dashboard-api/popup/?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          },
        };

        const response = await axios(config);
        dispatch(slice.actions.getPopupsSuccess(response.data.result));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getPopup(popupId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'get',
        url: `https://api.hmomen.com/dashboard-api/popup/${popupId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(config);
      dispatch(slice.actions.getPopupSuccess(response.data.result.popup));
      dispatch(slice.actions.getSchedulesSuccess(response.data.result.schedules));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function resetPopup() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getPopupSuccess({}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createPopup(newPopup, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(newPopup);

      const config = {
        method: 'post',
        url: 'https://api.hmomen.com/dashboard-api/popup?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };

      const response = await axios(config);
      dispatch(slice.actions.createPopupSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePopup(popupId, updatePopup, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(updatePopup);

      const config = {
        method: 'patch',
        url: `https://api.hmomen.com/dashboard-api/popup/${popupId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };
      const response = await axios(config);
      dispatch(slice.actions.updatePopupSuccess(response.data.result));
      dispatch(getPopup(popupId, accessToken));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------------------------

export function publishPopup(popupId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/popup/${popupId}/publish?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      // dispatch(slice.actions.publishpopupSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function hidePopup(popupId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/popup/${popupId}/hide?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      // dispatch(slice.actions.hidepopupSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deletePopup(popupId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'delete',
        url: `https://api.hmomen.com/dashboard-api/popup/${popupId}/?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      dispatch(slice.actions.deletePopupSuccess(popupId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteScheduledPopup(popupId, id, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'delete',
        url: `https://api.hmomen.com/dashboard-api/popup/${popupId}/scheduling/${id}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      dispatch(slice.actions.deleteScheduleSuccess(id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -------------------------------------------------------------------------
export function EditScheduledPopup(popupId, id, schedulData, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(schedulData);

      const config = {
        method: 'patch',
        url: `https://api.hmomen.com/dashboard-api/popup/${popupId}/scheduling/${id}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };
      const response = await axios(config);
      dispatch(slice.actions.updateScheduleSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function scheduledPopup(popupId, schedulData, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(schedulData);

      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/popup/${popupId}/scheduling?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };

      const response = await axios(config);
      dispatch(slice.actions.createScheduleSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
