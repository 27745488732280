import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
//
import { dispatch } from '../../store';
// ----------------------------------------------------------------------

const initialState = {
  designers: [],
  designer: {},
  error: '',
  success: false,
};

const slice = createSlice({
  name: 'designer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setSuccess(state) {
      state.success = false;
    },
    // GET designers
    getDesignersSuccess(state, action) {
      state.isLoading = false;
      state.designers = action.payload;
    },
    getDesignerSuccess(state, action) {
      state.isLoading = false;
      state.designer = action.payload;
    },

    // CREATE designer
    createDesignerSuccess(state, action) {
      const newdesigner = action.payload;
      state.isLoading = false;
      state.designers = [...state.designers, newdesigner];
      state.success = true;
    },

    // UPDATE designer
    updateDesignerSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.designers.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.designers = updateEvent;
      state.success = true;
    },

    // DELETE designer
    deleteDesignerSuccess(state, action) {
      const eventId = action.payload;
      const deleteEvent = state.designers.filter((event) => event.id !== eventId);
      state.designers = deleteEvent;
      state.success = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteConform, hasError, setSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getDesigners(accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'get',
        url: 'https://api.hmomen.com/dashboard-api/wall/designer/?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(config);
      dispatch(slice.actions.getDesignersSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDesigner(designerId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'get',
        url: `https://api.hmomen.com/dashboard-api/wall/designer/${designerId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(config);
      dispatch(slice.actions.getDesignerSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function resetDesigner() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getDesignerSuccess({}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createDesigner(newDesigner, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(newDesigner);

      const config = {
        method: 'post',
        url: 'https://api.hmomen.com/dashboard-api/wall/designer?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };

      const response = await axios(config);
      dispatch(slice.actions.createDesignerSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateDesigner(designerId, updateDesigner, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(updateDesigner);

      const config = {
        method: 'patch',
        url: `https://api.hmomen.com/dashboard-api/wall/designer/${designerId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };
      const response = await axios(config);
      dispatch(slice.actions.updateDesignerSuccess(response.data.result));
      dispatch(getDesigner(designerId, accessToken));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteDesigner(designerId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'delete',
        url: `https://api.hmomen.com/dashboard-api/wall/designer/${designerId}/?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      dispatch(slice.actions.deleteDesignerSuccess(designerId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function publishDesigner(designerId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/wall/designer/${designerId}/publish?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      // dispatch(slice.actions.publishBannerSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function hideDesigner(designerId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/wall/designer/${designerId}/hide?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      // dispatch(slice.actions.publishBannerSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
