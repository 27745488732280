// routes
import { PATH_MARKETING } from '../../../../routes/paths';

import SvgIconStyle from '../../../../components/SvgIconStyle';
// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  booking: getIcon('ic_booking'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Content Manager',

    items: [
      { title: 'Dashboard', path: PATH_MARKETING.Dashboard, icon: ICONS.dashboard },
      { title: 'Banner', path: PATH_MARKETING.contentManager.banner, icon: ICONS.menuItem },
      { title: 'Popup', path: PATH_MARKETING.contentManager.popup, icon: ICONS.menuItem },
      { title: 'Occasion', path: PATH_MARKETING.contentManager.occasion, icon: ICONS.menuItem },
      { title: 'Tag', path: PATH_MARKETING.contentManager.tag, icon: ICONS.menuItem },
      { title: 'UserShare', path: PATH_MARKETING.contentManager.userShare, icon: ICONS.menuItem },
    ],
  },
];

export default navConfig;
