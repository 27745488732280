// routes
import { PATH_SYSTEM } from '../../../../routes/paths';

import SvgIconStyle from '../../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  booking: getIcon('ic_booking'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  user: getIcon('ic_user'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Content Manager',

    items: [
      { title: 'Dashboard', path: PATH_SYSTEM.Dashboard, icon: ICONS.dashboard },
      { title: 'Users', path: PATH_SYSTEM.contentManager.users, icon: ICONS.user },
      { title: 'Roles', path: PATH_SYSTEM.contentManager.roles, icon: ICONS.menuItem },
      { title: 'Action', path: PATH_SYSTEM.contentManager.action, icon: ICONS.menuItem },
      { title: 'Localization', path: PATH_SYSTEM.contentManager.localization, icon: ICONS.menuItem },
      { title: 'Hijri', path: PATH_SYSTEM.contentManager.hijriDate, icon: ICONS.menuItem },
    ],
  },
];

export default navConfig;
