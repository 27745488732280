import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// Auth
import AuthReducer from './slices/auth';

// Marketing
import BannerReducer from './slices/MarketingDashboard/banner';
import PopupReducer from './slices/MarketingDashboard/popup';
import TagReducer from './slices/MarketingDashboard/tag';
import OccasionReducer from './slices/MarketingDashboard/occasion';
import ShareReducer from './slices/MarketingDashboard/share';

// Content
import KhatmaReducer from './slices/ContentDashboard/khatma';
import WallReducer from './slices/ContentDashboard/wall';
import TasbihReducer from './slices/ContentDashboard/tasbih';
import DesignerReducer from './slices/ContentDashboard/desinger';
import CategoryReducer from './slices/ContentDashboard/category';
import ReciterReducer from './slices/ContentDashboard/reciter';
import AudioReducer from './slices/ContentDashboard/audio';
import AudioHomeReducer from './slices/ContentDashboard/audioHome';
import PlaylistReducer from './slices/ContentDashboard/playlist';
import AlbumReducer from './slices/ContentDashboard/album';

// System
import LanguageReducer from './slices/SystemDashboard/localization';
import UsersReducer from './slices/SystemDashboard/users';
import ActionReducer from './slices/SystemDashboard/action';
import RolesReducer from './slices/SystemDashboard/roles';
import HijriDateReducer from './slices/SystemDashboard/hijriDate';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['auth'],
  // blacklist: ['auth.access_token'],
};

// const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['sortBy', 'checkout'],
// };

const rootReducer = combineReducers({
  auth: AuthReducer,

  // Marketing
  banner: BannerReducer,
  tag: TagReducer,
  occasion: OccasionReducer,
  share: ShareReducer,
  popup: PopupReducer,

  // Content
  khatma: KhatmaReducer,
  wall: WallReducer,
  tasbih: TasbihReducer,
  designer: DesignerReducer,
  category: CategoryReducer,
  reciter: ReciterReducer,
  album: AlbumReducer,
  playlist: PlaylistReducer,
  audio: AudioReducer,
  audioHome: AudioHomeReducer,

  // System
  user: UsersReducer,
  language: LanguageReducer,
  action: ActionReducer,
  role: RolesReducer,
  hijriDate: HijriDateReducer,
});

export { rootPersistConfig, rootReducer };
