import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
//
import { dispatch } from '../../store';
// ----------------------------------------------------------------------

const initialState = {
  walls: [],
  wall: {},
  error: '',
  state: false,
  success: false,
};

const slice = createSlice({
  name: 'wall',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setSuccess(state) {
      state.success = false;
      state.state = false;
    },
    // GET walls
    getWallsSuccess(state, action) {
      state.isLoading = false;
      state.walls = action.payload;
    },
    getWallSuccess(state, action) {
      state.isLoading = false;
      state.wall = action.payload;
    },

    // CREATE wall
    createWallSuccess(state, action) {
      const newwall = action.payload;
      state.isLoading = false;
      state.walls = [...state.walls, newwall];
      state.success = true;
    },

    // UPDATE wall
    updateWallSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.walls.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.walls = updateEvent;
      state.success = true;
    },

    // DELETE wall
    deleteWallSuccess(state, action) {
      const eventId = action.payload;
      const deleteEvent = state.walls.filter((event) => event.id !== eventId);
      state.walls = deleteEvent;
      state.state = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteConform, hasError, setSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getWalls(accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'get',
        url: 'https://api.hmomen.com/dashboard-api/wall/?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(config);
      dispatch(slice.actions.getWallsSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getWall(wallId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'get',
        url: `https://api.hmomen.com/dashboard-api/wall/${wallId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(config);
      dispatch(slice.actions.getWallSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function resetWall() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getWallSuccess({}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createWall(newWall, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(newWall);

      const config = {
        method: 'post',
        url: 'https://api.hmomen.com/dashboard-api/wall?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };

      const response = await axios(config);
      dispatch(slice.actions.createWallSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateWall(wallId, updateWall, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(updateWall);

      const config = {
        method: 'patch',
        url: `https://api.hmomen.com/dashboard-api/wall/${wallId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };
      const response = await axios(config);
      dispatch(slice.actions.updateWallSuccess(response.data.result));
      dispatch(getWall(wallId, accessToken));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------------------------

export function publishWall(wallId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/wall/${wallId}/publish?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      getWalls(accessToken);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function hideWall(wallId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/wall/${wallId}/hide?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      // dispatch(slice.actions.hidewallsuccess(response.data.result));
      getWalls(accessToken);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deletewall(wallId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'delete',
        url: `https://api.hmomen.com/dashboard-api/wall/${wallId}/?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      dispatch(slice.actions.deleteWallSuccess(wallId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
