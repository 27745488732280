import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { getUser } from '../redux/slices/auth';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};
const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};
const apiKey = '1a502da1-00e4-49bb-987a-ca3ba232a846';
const accessToken = localStorage.getItem('accessToken');

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const exp = localStorage.getItem('exp');
  useEffect(() => {
    const initialize = async () => {
      try {
        // const accessToken = useSelector((state) => state.auth.access_token);
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken, exp);
          dispatch(getUser(accessToken));
          const config = {
            method: 'get',
            url: `https://api.hmomen.com/dashboard-api/user/me?api_key=${apiKey}`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };

          const response = await axios(config);
          const user = {
            ...response.data.result.haqibat_almumin_profile,
            displayName: response.data.result.haqibat_almumin_profile.full_name,
            isPublic: true,
          };

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (accessToken, exp, waitFun) => {
    // const response = await axios.post('/api/account/login', {
    //   email,
    //   password,
    // });
    // const { accessToken, user } = response.data;

    const config = {
      method: 'get',
      url: `https://api.hmomen.com/dashboard-api/user/me?api_key=${apiKey}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response = await axios(config);
    const user = {
      ...response.data.result.haqibat_almumin_profile,
      displayName: response.data.result.haqibat_almumin_profile.full_name,
      isPublic: true,
    };

    setSession(accessToken, exp);

    waitFun(true);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  // const register = async (email, password, firstName, lastName) => {
  //   const response = await axios.post('/api/account/register', {
  //     email,
  //     password,
  //     firstName,
  //     lastName,
  //   });
  //   const { accessToken, user } = response.data;

  //   localStorage.setItem('accessToken', accessToken);

  //   dispatch({
  //     type: 'REGISTER',
  //     payload: {
  //       user,
  //     },
  //   });
  // };

  const logout = async () => {
    // const accessToken = useSelector((state) => state.auth.access_token);

    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        // register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
