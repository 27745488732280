import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
//
import { dispatch } from '../../store';
// ----------------------------------------------------------------------

const initialState = {
  albums: [],
  album: {},
  error: '',
  state: false,
  success: false,
};

const slice = createSlice({
  name: 'album',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setSuccess(state) {
      state.success = false;
      state.state = false;
    },
    // GET albums
    getAlbumsSuccess(state, action) {
      state.isLoading = false;
      state.albums = action.payload;
    },
    getAlbumSuccess(state, action) {
      state.isLoading = false;
      state.album = action.payload;
    },

    // CREATE album
    createAlbumSuccess(state, action) {
      const newalbum = action.payload;
      state.isLoading = false;
      state.albums = [...state.albums, newalbum];
      state.success = true;
    },

    // UPDATE album
    updateAlbumSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.albums.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.albums = updateEvent;
      state.success = true;
    },

    // DELETE album
    deleteAlbumSuccess(state, action) {
      const eventId = action.payload;
      const deleteEvent = state.albums.filter((event) => event.id !== eventId);
      state.albums = deleteEvent;
      state.state = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteConform, hasError, setSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getAlbums(accessToken, lang) {
  return async () => {
    if (lang) {
      dispatch(slice.actions.startLoading());
      try {
        const config = {
          method: 'get',
          url: `https://api.hmomen.com/dashboard-api/audio/album/?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846&lang=${lang}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          },
        };

        const response = await axios(config);
        dispatch(slice.actions.getAlbumsSuccess(response.data.result));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    } else {
      dispatch(slice.actions.startLoading());
      try {
        const config = {
          method: 'get',
          url: 'https://api.hmomen.com/dashboard-api/audio/album/?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          },
        };

        const response = await axios(config);
        dispatch(slice.actions.getAlbumsSuccess(response.data.result));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getAlbum(albumId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'get',
        url: `https://api.hmomen.com/dashboard-api/audio/album/${albumId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(config);
      dispatch(slice.actions.getAlbumSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function resetAlbum() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getAlbumSuccess({}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createAlbum(newalbum, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(newalbum);

      const config = {
        method: 'post',
        url: 'https://api.hmomen.com/dashboard-api/audio/album?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };

      const response = await axios(config);
      dispatch(slice.actions.createAlbumSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateAlbum(albumId, updatealbum, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(updatealbum);

      const config = {
        method: 'patch',
        url: `https://api.hmomen.com/dashboard-api/audio/album/${albumId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };
      const response = await axios(config);
      dispatch(slice.actions.updateAlbumSuccess(response.data.result));
      dispatch(getAlbum(albumId, accessToken));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------------------------

export function publishAlbum(albumId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/audio/album/${albumId}/publish?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function hideAlbum(albumId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/audio/album/${albumId}/hide?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      // dispatch(slice.actions.hidealbumsuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteAlbum(albumId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'delete',
        url: `https://api.hmomen.com/dashboard-api/audio/album/${albumId}/?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      dispatch(slice.actions.deleteAlbumSuccess(albumId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
