import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import ContentDashboardLayout from '../layouts/dashboard/navbar/ContentNavbar';
import SystemDashboardLayout from '../layouts/dashboard/navbar/SystemNavbar';
import MarketingDashboardLayout from '../layouts/dashboard/navbar/MarketingNavbar';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },

        {
          path: 'conformLog',
          element: (
            <GuestGuard>
              <ConformLog />{' '}
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: 'Home',

      element: (
        <AuthGuard>
          <Intro />
        </AuthGuard>
      ),
    },
    {
      path: '/',

      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
      index: true,
    },
    // Content
    {
      path: 'content',
      element: (
        <AuthGuard>
          <ContentDashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'dashboard', element: <ContentDashboard /> },
        {
          path: 'contentManager',
          element: <ContentManager />,
          children: [
            { element: <Navigate to="/content/contentManager/tasbih" replace />, index: true },
            { path: 'file', element: <Wallpaper /> },
            { path: 'wallpaperAdd', element: <WallpaperEntity /> },
            { path: 'khatma', element: <Khatma /> },
            { path: 'khatmaAdd', element: <KhatmaEntity /> },
            { path: 'tasbih', element: <Tasbih /> },
            { path: 'tasbihAdd', element: <TasbihEntity /> },
            { path: 'designer', element: <Designer /> },
            { path: 'designerAdd', element: <DesignerEntity /> },
            { path: 'category', element: <Category /> },
            { path: 'categoryAdd', element: <CategoryEntity /> },
            { path: 'audio', element: <Audio /> },
            { path: 'audioAdd', element: <AudioEntity /> },
            { path: 'audioHome', element: <AudioHome /> },
            { path: 'audioHomeAdd', element: <AudioHomeEntity /> },
            { path: 'album', element: <Album /> },
            { path: 'albumAdd', element: <AlbumEntity /> },
            { path: 'playlist', element: <Playlist /> },
            { path: 'playlistAdd', element: <PlaylistEntity /> },
            { path: 'reciter', element: <Reciter /> },
            { path: 'reciterAdd', element: <ReciterEntity /> },
            // { path: 'add', element: },
          ],
        },
      ],
    },

    // Marketing

    {
      path: 'marketing',
      element: (
        <AuthGuard>
          <MarketingDashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'dashboard', element: <MarketingDashboard /> },
        {
          path: 'contentManager',
          element: <MarketingManager />,
          children: [
            { element: <Navigate to="/marketing/contentManager/banner" replace />, index: true },
            { path: 'banner', element: <Banner /> },
            { path: 'popup', element: <Popup /> },
            { path: 'occasion', element: <Occasion /> },
            { path: 'tag', element: <Tag /> },
            { path: 'userShare', element: <UserShare /> },
            { path: 'bannerAdd', element: <BannerEntity /> },
            { path: 'popupAdd', element: <PopupEntity /> },
            { path: 'occasionAdd', element: <OccasionEntity /> },
            { path: 'tagAdd', element: <TagEntity /> },
            { path: 'userShareAdd', element: <UserShareEntity /> },
          ],
        },
      ],
    },

    // System
    {
      path: 'system',
      element: (
        <AuthGuard>
          <SystemDashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'dashboard', element: <SystemDashboard /> },
        {
          path: 'contentManager',
          element: <SystemManager />,
          children: [
            { element: <Navigate to="/system/contentManager/users" replace />, index: true },
            { path: 'users', element: <Users /> },
            { path: 'usersAdd', element: <UsersEntity /> },
            { path: 'roles', element: <Roles /> },
            { path: 'rolesAdd', element: <RolesEntity /> },
            { path: 'localization', element: <Localization /> },
            { path: 'localizationAdd', element: <LocalizationEntity /> },
            { path: 'hijriDate', element: <HijriDate /> },
            { path: 'hijriDateAdd', element: <HijriDateEntity /> },
            { path: 'action', element: <Action /> },
            { path: 'actionAdd', element: <ActionEntity /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'back', element: <Navigate to="/home" /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ConformLog = Loadable(lazy(() => import('../pages/auth/ConformLog')));

// DASHBOARD
// Content Dashboard
const ContentDashboard = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/ContnetDashboard')));
const ContentManager = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/ContentManager')));
const Khatma = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Khatma/Khatma')));
const KhatmaEntity = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Khatma/KhatmaEntity')));
const Tasbih = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Tasbih/Tasbih')));
const TasbihEntity = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Tasbih/TasbihEntity')));
const Wallpaper = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Wall/Wall')));
const WallpaperEntity = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Wall/WallEntity')));
const Designer = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Designer/Designer')));
const DesignerEntity = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Designer/DesignerEntity')));
const Category = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Category/Category')));
const CategoryEntity = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Category/CategoryEntity')));
const Audio = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Audio/Audio')));
const AudioEntity = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Audio/AudioEntity')));
const AudioHome = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/AudioHome/AudioHome')));
const AudioHomeEntity = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/AudioHome/AudioHomeEntity')));
const Album = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Album/Album')));
const AlbumEntity = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Album/AlbumEntity')));
const Playlist = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Playlist/Playlist')));
const PlaylistEntity = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Playlist/PlaylistEntity')));
const Reciter = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Reciter/Reciter')));
const ReciterEntity = Loadable(lazy(() => import('../pages/dashboard/ContentDashboard/Reciter/ReciterEntity')));

// System Dashboard
const SystemDashboard = Loadable(lazy(() => import('../pages/dashboard/SystemDashboard/SystemDashboard')));
const SystemManager = Loadable(lazy(() => import('../pages/dashboard/SystemDashboard/SystemManager')));
const Localization = Loadable(lazy(() => import('../pages/dashboard/SystemDashboard/Language/Language')));
const LocalizationEntity = Loadable(lazy(() => import('../pages/dashboard/SystemDashboard/Language/LanguageEntity')));
const HijriDate = Loadable(lazy(() => import('../pages/dashboard/SystemDashboard/HijriDate/HijriDate')));
const HijriDateEntity = Loadable(lazy(() => import('../pages/dashboard/SystemDashboard/HijriDate/HijriDateEntity')));
const Action = Loadable(lazy(() => import('../pages/dashboard/SystemDashboard/Action/Action')));
const ActionEntity = Loadable(lazy(() => import('../pages/dashboard/SystemDashboard/Action/ActionEntity')));
const Roles = Loadable(lazy(() => import('../pages/dashboard/SystemDashboard/Roles/Roles')));
const RolesEntity = Loadable(lazy(() => import('../pages/dashboard/SystemDashboard/Roles/RolesEntity')));
const Users = Loadable(lazy(() => import('../pages/dashboard/SystemDashboard/Users/Users')));
const UsersEntity = Loadable(lazy(() => import('../pages/dashboard/SystemDashboard/Users/UsersEntity')));

// Marketing Dashboard
const MarketingDashboard = Loadable(lazy(() => import('../pages/dashboard/MarketingDashboard/MarketingDashboard')));
const MarketingManager = Loadable(lazy(() => import('../pages/dashboard/MarketingDashboard/MarketingManager')));
const Banner = Loadable(lazy(() => import('../pages/dashboard/MarketingDashboard/Banner/Banner')));
const BannerEntity = Loadable(lazy(() => import('../pages/dashboard/MarketingDashboard/Banner/BannerEntity')));
const Popup = Loadable(lazy(() => import('../pages/dashboard/MarketingDashboard/Popup/Popup')));
const PopupEntity = Loadable(lazy(() => import('../pages/dashboard/MarketingDashboard/Popup/PopupEntity')));
const Occasion = Loadable(lazy(() => import('../pages/dashboard/MarketingDashboard/Occasion/Occasion')));
const OccasionEntity = Loadable(lazy(() => import('../pages/dashboard/MarketingDashboard/Occasion/OccasionEntity')));
const Tag = Loadable(lazy(() => import('../pages/dashboard/MarketingDashboard/Tag/Tag')));
const TagEntity = Loadable(lazy(() => import('../pages/dashboard/MarketingDashboard/Tag/TagEntity')));
const UserShare = Loadable(lazy(() => import('../pages/dashboard/MarketingDashboard/Share/Share')));
const UserShareEntity = Loadable(lazy(() => import('../pages/dashboard/MarketingDashboard/Share/ShareEntity')));

// Intro
const Intro = Loadable(lazy(() => import('../pages/Intro')));

// MAIN
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
