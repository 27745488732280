import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
//
import { dispatch } from '../../store';
// ----------------------------------------------------------------------

const initialState = {
  hijriDates: [],
  hijriDate: {},
  error: '',
  success: false,
};

const slice = createSlice({
  name: 'hijriDates',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setSuccess(state) {
      state.success = false;
    },
    // GET hijriDates
    getHijriDatesSuccess(state, action) {
      state.isLoading = false;
      state.hijriDates = action.payload;
    },
    getHijriDateSuccess(state, action) {
      state.isLoading = false;
      state.hijriDate = action.payload;
    },

    // CREATE hijriDate
    createHijriDateSuccess(state, action) {
      const newHijriDate = action.payload;
      state.isLoading = false;
      state.hijriDates = [...state.hijriDates, newHijriDate];
      state.success = true;
    },

    // UPDATE hijriDate
    updateHijriDateSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.hijriDates.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.hijriDates = updateEvent;
      state.success = true;
    },

    // DELETE hijriDate
    deleteHijriDateSuccess(state, action) {
      const eventId = action.payload;
      const deleteEvent = state.hijriDates.filter((event) => event.id !== eventId);
      state.hijriDates = deleteEvent;
      state.success = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteConform, hasError, setSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getHijriDates(accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'get',
        url: 'https://api.hmomen.com/dashboard-api/hijri-date?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios(config);
      dispatch(slice.actions.getHijriDatesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getHijriDate(hijriDateId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'get',
        url: `https://api.hmomen.com/dashboard-api/hijri-date/${hijriDateId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,

        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(config);
      dispatch(slice.actions.getHijriDateSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function resetHijriDate() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getHijriDateSuccess({}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createHijriDate(newHijriDate, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(newHijriDate);

      const config = {
        method: 'post',
        url: 'https://api.hmomen.com/dashboard-api/hijri-date?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };

      const response = await axios(config);
      dispatch(slice.actions.createHijriDateSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateHijriDate(hijriDateId, updateHijriDate, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(updateHijriDate);

      const config = {
        method: 'patch',
        url: `https://api.hmomen.com/dashboard-api/hijri-date/${hijriDateId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,

        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };
      const response = await axios(config);
      dispatch(slice.actions.updateHijriDateSuccess(response.data));
      dispatch(getHijriDate(hijriDateId, accessToken));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteHijriDate(hijriDateId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'delete',
        url: `https://api.hmomen.com/dashboard-api/hijri-date/${hijriDateId}/?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      dispatch(slice.actions.deleteHijriDateSuccess(hijriDateId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function assignDefaluthijriDate(hijriDateId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/hijriDate/${hijriDateId}/hide?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      // dispatch(slice.actions.publishBannerSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
