import { Container, Typography } from '@mui/material';
import useLocales from '../../../hooks/useLocales';

export default function ContnetDashboard() {
  const { translate } = useLocales();

  return (
    <>
      {' '}
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {translate('content.DashboardGreetings')}
        </Typography>
      </Container>
    </>
  );
}
