// routes
import moment from 'moment';

import { PATH_AUTH } from '../routes/paths';
//
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  // const decoded = jwtDecode(accessToken);
  const ex = localStorage.getItem('exp');
  const exp = new Date(ex);
  const currentTime = Date.now() / 1000;
  return exp > currentTime;
};

const handleTokenExpired = (exp) => {
  let expiredTimer;
  const currentTime = moment();
  const date = new Date(exp);
  const timeLeft = moment.duration(moment(date.toISOString()).diff(currentTime));

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s

  clearTimeout(expiredTimer);
  expiredTimer = setTimeout(() => {
    alert('Token expired');
    localStorage.removeItem('accessToken');
    window.location.href = PATH_AUTH.login;
  }, timeLeft.asMilliseconds());
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    const exp = localStorage.getItem('exp');

    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
