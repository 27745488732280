import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
//
import { dispatch } from '../../store';
// ----------------------------------------------------------------------

const initialState = {
  playlists: [],
  playlist: {},
  error: '',
  success: false,
  add_success: false,
  remove_success: false,
};

const slice = createSlice({
  name: 'playlist',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET playlists
    getPlaylistsSuccess(state, action) {
      state.isLoading = false;
      state.playlists = action.payload;
    },

    // GET playlist

    getPlaylistSuccess(state, action) {
      state.isLoading = false;
      state.playlist = action.payload;
    },

    // CREATE playlist
    createPlaylistSuccess(state, action) {
      const newPlaylist = action.payload;
      state.isLoading = false;
      state.playlists = [...state.playlists, newPlaylist];
      state.success = true;
    },

    setSuccessPlaylist(state) {
      state.success = false;
      state.add_success = false;
      state.remove_success = false;
    },
    addAudioToPlaylistSuccess(state) {
      state.add_success = true;
    },
    removeAudioFromPlaylistSuccess(state) {
      state.remove_success = true;
    },

    // UPDATE playlist
    updatePlaylistSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.playlists.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.playlists = updateEvent;
      state.success = true;
    },

    // DELETE playlist
    deletePlaylistSuccess(state, action) {
      const eventId = action.payload;
      const deleteEvent = state.playlists.filter((event) => event.id !== eventId);
      state.playlists = deleteEvent;
      state.success = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteConform, hasError, setSuccessPlaylist } = slice.actions;

// ----------------------------------------------------------------------

export function getPlaylists(accessToken, lang) {
  return async () => {
    if (lang) {
      dispatch(slice.actions.startLoading());
      try {
        const config = {
          method: 'get',
          url: `https://api.hmomen.com/dashboard-api/audio/playlist?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846&lang=${lang}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          },
        };

        const response = await axios(config);
        dispatch(slice.actions.getPlaylistsSuccess(response.data.result));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    } else {
      dispatch(slice.actions.startLoading());
      try {
        const config = {
          method: 'get',
          url: 'https://api.hmomen.com/dashboard-api/audio/playlist?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          },
        };

        const response = await axios(config);
        dispatch(slice.actions.getPlaylistsSuccess(response.data.result));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

// ----------------------------------------------------------

export function getPlaylist(playlistId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'get',
        url: `https://api.hmomen.com/dashboard-api/audio/playlist/${playlistId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(config);
      dispatch(slice.actions.getPlaylistSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function resetPlaylist() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getPlaylistSuccess({}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createPlaylist(newPlaylist, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(newPlaylist);

      const config = {
        method: 'post',
        url: 'https://api.hmomen.com/dashboard-api/audio/playlist?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };

      const response = await axios(config);
      dispatch(slice.actions.createPlaylistSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePlaylist(playlistId, updatedPlaylist, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(updatedPlaylist);

      const config = {
        method: 'patch',
        url: `https://api.hmomen.com/dashboard-api/audio/playlist/${playlistId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };
      const response = await axios(config);
      dispatch(slice.actions.updatePlaylistSuccess(response.data.result));
      dispatch(getPlaylist(playlistId, accessToken));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deletePlaylist(playlistId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'delete',
        url: `https://api.hmomen.com/dashboard-api/audio/playlist/${playlistId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      dispatch(slice.actions.deletePlaylistSuccess(playlistId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function addAudioToPlaylist(audioId, playlistId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/audio/playlist/${playlistId}/add/${audioId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      dispatch(slice.actions.addAudioToPlaylistSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function removeAudioFromPlaylist(audioId, playlistId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/audio/playlist/${playlistId}/remove/${audioId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      dispatch(slice.actions.removeAudioFromPlaylistSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
