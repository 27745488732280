import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
//
import { dispatch } from '../../store';
// ----------------------------------------------------------------------

const initialState = {
  audios: [],
  audio: {},
  error: '',
  isLoading: false,
  state: false,
  success: false,
  audio_success: false,
};

const slice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    isLoading(state) {
      return state.isLoading === true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setSuccess(state) {
      state.isLoading = false;
      state.success = false;
      state.audio_success = false;
    },
    // GET audios
    getAudiosSuccess(state, action) {
      state.isLoading = false;
      state.audios = action.payload;
    },
    getAudioSuccess(state, action) {
      state.isLoading = false;
      state.audio = action.payload;
    },

    // CREATE audio
    createAudioSuccess(state, action) {
      const newAudio = action.payload;
      state.isLoading = false;
      state.audios = [...state.audios, newAudio];
      state.audio  = newAudio;
      state.success = true;
    },
    // CREATE audio File
    createAudioFileSuccess(state) {
      state.audio_success = true;
    },

    // UPDATE audio
    updateAudioSuccess(state, action) {
      const event = action.payload;
      const updateEvent = state.audios.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }
        return _event;
      });

      state.isLoading = false;
      state.audios = updateEvent;
      state.success = true;
    },

    // DELETE audio
    deleteAudioSuccess(state, action) {
      const eventId = action.payload;
      const deleteEvent = state.audios.filter((event) => event.id !== eventId);
      state.audios = deleteEvent;
      state.success = true;
    },

    // DELETE audio File
    deleteAudioFileSuccess(state) {
      state.success = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteConform, hasError, setSuccess, isLoading } = slice.actions;

// ----------------------------------------------------------------------

export function getAudios(accessToken, lang) {
  return async () => {
    if (lang) {
      dispatch(slice.actions.startLoading());
      try {
        const config = {
          method: 'get',
          url: `https://api.hmomen.com/dashboard-api/audio?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846&lang=${lang}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          },
        };

        const response = await axios(config);
        dispatch(slice.actions.getAudiosSuccess(response.data.result));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    } else {
      dispatch(slice.actions.startLoading());
      try {
        const config = {
          method: 'get',
          url: 'https://api.hmomen.com/dashboard-api/audio?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          },
        };

        const response = await axios(config);
        dispatch(slice.actions.getAudiosSuccess(response.data.result));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getAudio(audioId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'get',
        url: `https://api.hmomen.com/dashboard-api/audio/${audioId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios(config);
      dispatch(slice.actions.getAudioSuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function resetAudio() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getAudioSuccess({}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createAudio(newAudio, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(newAudio);

      const config = {
        method: 'post',
        url: 'https://api.hmomen.com/dashboard-api/audio?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };

      const response = await axios(config);
      dispatch(slice.actions.createAudioSuccess(response.data.result));
      dispatch(getAudio(response.data.result.id, accessToken));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function createAudioFile(file, audioId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const form = new FormData();
      form.append('file', file);

      const config = {
        url: `https://api.hmomen.com/dashboard-api/audio/${audioId}/file?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        method: 'post',

        headers: { Authorization: `Bearer ${accessToken}` },
        processData: false,
        mimeType: 'multipart/form-data',
        contentType: false,
        data: form,
      };
      await axios(config);
      dispatch(slice.actions.createAudioFileSuccess());
      dispatch(getAudio(audioId, accessToken));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateAudio(audioId, updateAudio, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(updateAudio);

      const config = {
        method: 'patch',
        url: `https://api.hmomen.com/dashboard-api/audio/${audioId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        data,
      };
      const response = await axios(config);
      dispatch(slice.actions.updateAudioSuccess(response.data.result));
      dispatch(getAudio(audioId, accessToken));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// -----------------------------------------

export function publishAudio(audioId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/audio/${audioId}/publish?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function hideAudio(audioId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'post',
        url: `https://api.hmomen.com/dashboard-api/audio/${audioId}/hide?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      // dispatch(slice.actions.hideaudiosuccess(response.data.result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteAudio(audioId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'delete',
        url: `https://api.hmomen.com/dashboard-api/audio/${audioId}?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      dispatch(slice.actions.deleteAudioSuccess(audioId));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteAudioFile(audioId, accessToken) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const config = {
        method: 'delete',
        url: `https://api.hmomen.com/dashboard-api/audio/${audioId}/file?api_key=1a502da1-00e4-49bb-987a-ca3ba232a846`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      await axios(config);
      dispatch(slice.actions.deleteAudioFileSuccess());
      dispatch(getAudio(audioId, accessToken));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
